import { DispatchrService } from "@/api"
import { PostFile } from "@/api/reseller"
import { PostData } from "@/types/socials"
import { imgListExtension } from "@/utils/media"
import { getMediaRatio } from "@/utils/media-ratio"
import dayjs from "dayjs"

class SocialsPostService {
    haveUnsavedDataPost(post: PostData) {
        if ((post?.description && post?.description !== "") || (post?.mediasLocal && post?.mediasLocal?.length > 0)) {
            return true
        }
        return false
    }

    formattedMedia(postMedia: PostFile) {
        const newMedias = []
        postMedia?.medias?.forEach((media) => {
            const metadata = getMediaRatio({
                naturalSize: { width: media.dimensions.width, height: media.dimensions.height },
            })
            const mediaExtension = media?.url?.split(".").pop().toLowerCase()
            newMedias.push({
                url: media.url,
                metadata,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            })
        })
        return newMedias
    }

    formattedPublishAt({ postItems, publishAtData, publishDate, businessesTimezone }) {
        const postsError = postItems.filter((item) => item.status === "FAILED")
        const publishAtError = []
        const publishAtWithoutError = []

        if (postsError.length > 0) {
            postsError.forEach((postError) => {
                let data = publishAtData.find(
                    (item) => item.locationId === postError?.locationId && item.provider === postError?.provider
                )

                if (data) {
                    data = {
                        ...data,
                        date: dayjs(publishDate).tz(businessesTimezone[data.locationId]).format("YYYY-MM-DDTHH:mm:ss"),
                        originalDate: publishDate,
                    }

                    publishAtError.push(data)
                }
            })
        }

        // Collect all publishAt that are not in publishAtError
        publishAtData.forEach((publishAtItem) => {
            const hasError = publishAtError.some(
                (errorItem) =>
                    errorItem.locationId === publishAtItem.locationId && errorItem.provider === publishAtItem.provider
            )

            if (!hasError) {
                publishAtWithoutError.push(publishAtItem)
            }
        })

        return publishAtError.concat(publishAtWithoutError)
    }

    hidePostAlert(locationIds: string[]) {
        return DispatchrService.put("location-state", {
            locationIds,
            showPostAlert: false,
        })
    }
}

export default new SocialsPostService()
