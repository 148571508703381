import deburr from "lodash.deburr"
const mapNonGsm7Chars = (text: string) => {
    let tempText = text.replace(/ê/g, "__E_ACCENT__").replace(/ç/g, "__C_CEDILLA__")

    // Utilisation de deburr pour enlever les autres accents
    tempText = deburr(tempText)

    // Restauration des marqueurs `ê` et `ç`
    return tempText.replace(/__E_ACCENT__/g, "ê").replace(/__C_CEDILLA__/g, "ç")
}

export { mapNonGsm7Chars }
